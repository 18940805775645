// 高级旗舰版
const senior = {
    home: {
        url: "/addons/chatgpt/index/pc_config",
        method: "POST",
        desc: "旗舰版 首页数据"
    },
    block: {
        url: "/addons/chatgpt/block/index",
        method: "POST",
        desc: "旗舰版 banner"
    },
    recommend: {
        url: "/addons/chatgpt/recommend_works/index",
        method: "POST",
        desc: "社区资源 推荐列表"
    },
    collect: {
        url: "/addons/chatgpt/recommend_works/operate",
        method: "POST",
        desc: "社区资源 收藏喜欢"
    },
    create_agent: {
        url: "/addons/chatgpt/agent/create_agent",
        method: "POST",
        desc: "社区资源 新建智能体"
    },
    topic: {
        url: "/addons/chatgpt/agent/topic",
        method: "POST",
        desc: " 智能体分类列表"
    },
    agent_list: {
        url: "/addons/chatgpt/agent/prompts",
        method: "POST",
        desc: " 智能体列表"
    },
    user_index: {
        url: "/addons/chatgpt/user/index",
        method: "POST",
        desc: "个人中心"
    },
    followList: {
        url: "/addons/chatgpt/follow/followList",
        method: "POST",
        desc: "关注粉丝列表"
    },
    follow: {
        url: "/addons/chatgpt/follow/follow",
        method: "POST",
        desc: "关注取关"
    },
    voteList: {
        url: "/addons/chatgpt/recommend_works/voteList",
        method: "POST",
        desc: "收藏列表"
    },
    zntchatList: {
        url: "/addons/chatgpt/agent/used_prompt",
        method: "POST",
        desc: "智能体聊天列表"
    },
    znthistory: {
        url: "/addons/chatgpt/web/history_write",
        method: "POST",
        desc: "智能体聊天历史"
    },
    delzntlist: {
        url: "/addons/chatgpt/agent/del_agent_record",
        method: "POST",
        desc: "删除智能体聊天"
    },
    new_mode_list: {
        url: "/addons/chatgpt/web/get_mode_new",
        method: "POST",
        desc: "新模型接口"
    },
    saveAgentGroup: {
        url: "/addons/chatgpt/agent/saveAgentGroup",
        method: "POST",
        desc: "新建智能体列表会话"
    },
    agent_prompt_group: {
        url: "/addons/chatgpt/agent/agent_prompt_group",
        method: "POST",
        desc: "查询智能体会话列表"
    },
    agent_history: {
        url: "/addons/chatgpt/agent/agent_history",
        method: "POST",
        desc: "查询智能体会话聊天记录"
    },
    
    
    
    
    



}

export default senior
