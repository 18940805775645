import Vue from "vue"
import VueRouter from "vue-router"
import Redirect from "@/views/Redirect.vue"
import store from "@/store/index"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

let routes = [
	{
		path: "*",
		component: () => import("../views/PageNotFound.vue")
	},
	{
		path: "/",
		name: "Home",
		redirect: "/ai/home"
	},
	// {
	// 	path: "/ai",
	// 	name: "Ai",
	// 	// layout: "dashboard",
	// 	component: () => import("../views/Square.vue"),
	// 	meta: { title: "首页", keepAlive: true }
	// },
	{
		path: "/ai",
		name: "NewAi",
		// layout: "dashboard",
		component: () => import("../views/Square.vue"),
		meta: { title: "首页", keepAlive: true },
		// path: "/square",
		// name: "square",
		// component: () => import("../views/Square.vue"),
		// meta: { title: "广场" },
		children: [
			{
				path: "home",
				name: "home",
				component: () => import("../views/AiHome.vue"),
				meta: { title: "首页", auth: false }
			},
			{
				path: "index",
				name: "index",
				component: () => import("../views/NewAi.vue"),
				meta: { title: "对话首页", auth: false },
			},
			{
				path: "aitools",
				name: "aitools",
				component: () => import("../views/AiTools.vue"),
				meta: { title: "ai工具", auth: false }
			},
			{
				path: "salecenter",
				name: "salecenter",
				component: () => import("../views/SaleCenter.vue"),
				meta: { title: "分销中心",   keepAlive: true }
			},
			{
				path: "aippt",
				name: "aippt",
				component: () => import("../views/Aippt.vue"),
				meta: { title: "广场内AIPPT", auth: false }
			},
			{
				path: "community",
				name: "community",
				component: () => import("../views/Community.vue"),
				meta: { title: "广场的社区资源", auth: false }
			},
			{
				path: "mindmap",
				name: "mindmap",
				component: () => import("../views/MindMap.vue"),
				meta: { title: "广场的思维导图", auth: false }
			},
			{
				path: "aivideo",
				name: "aivideo",
				component: () => import("../views/CreateVideo.vue"),
				meta: { title: "广场到文生视频", auth: false }
			},
			{
				path: "professional",
				name: "gcDrawingProfessional",
				component: () => import("../views/DrawingProfessional.vue"),
				meta: { title: "广场到专业生图", auth: false }
			},
			{
				path: "canvas",
				name: "gcDrawingCanvas",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: { title: "广场到以文生图", auth: false }
			},
			{
				path: "picture",
				name: "gcDrawingPicture",
				component: () => import("../views/DrawingPicture.vue"),
				meta: { title: "广场到以图生图", auth: false }
			},
			{
				path: "drawlxsd",
				name: "gcDrawlxsd",
				component: () => import("../views/Drawlxsd.vue"),
				meta: { title: "广场到灵犀生图", auth: false }
			},
			{
				path: "myworks",
				name: "myworks",
				component: () => import("../views/MyWorks.vue"),
				meta: { title: "广场我的作品", auth: false }
			},
			{
				path: "profile",
				name: "Profile",
				// layout: "dashboard",
				component: () => import("../views/Profile.vue"),
				meta: { title: "用户信息", keepAlive: true }
			},
			{
				path: "recharge",
				name: "Recharge",
				// layout: "dashboard",
				component: () => import("../views/Recharge.vue"),
				meta: {
					title: "充值中心", keepAlive: true,
					// layoutClass: "layout_profile"
				}
			},
			{
				path: "dashboard",
				name: "Dashboard",
				// layout: "dashboard",
				component: () => import("../views/Dashboard.vue"),
				meta: { title: "仪表盘", keepAlive: true }
			},
			{
				path: "write",
				name: "WriteIndex",
				component: () => import("../views/Write.vue"),
				meta: { title: "工具", auth: false }
			},
			{
				path: "role",
				name: "RoleIndex",
				component: () => import("../views/Role.vue"),
				meta: { title: "角色", auth: false }
			},
		]
	},
	{
		path: '/addznt',
		name: 'addznt',
		component: () => import("../views/AddZnt.vue"),
		meta: { title: "添加智能体", auth: false }
	},
	{
		path: "/write",
		name: "Write",
		// layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "创作" },
		children: [
			// {
			// 	path: "/",
			// 	name: "WriteIndex",
			// 	component: () => import("../views/Write.vue"),
			// 	meta: { keepAlive: true }
			// },
			{
				path: "create",
				name: "WriteCreate",
				component: () => import("../views/WriteCreate.vue"),
				meta: { title: "生成", auth: true }
			}
		]
	},
	{
		path: "/role",
		name: "Role",
		// layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "角色" },
		children: [
			{
				path: "chat",
				name: "RoleChat",
				component: () => import("../views/RoleChat.vue"),
				meta: { title: "聊天", auth: true }
			}
		]
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: Redirect,
		redirect: "/",
		meta: { title: "绘画" },
		children: [
			{
				path: "/",
				name: "DrawingIndex",
				layout: "dashboard",
				component: () => import("../views/Drawing.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "canvas",
				name: "DrawingCanvas",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: { title: "以文生图", auth: true }
			},
			{
				path: "picture",
				name: "DrawingPicture",
				component: () => import("../views/DrawingPicture.vue"),
				meta: { title: "以图生图", auth: true }
			},
			{
				path: "professional",
				name: "DrawingProfessional",
				component: () => import("../views/DrawingProfessional.vue"),
				meta: { title: "专业生图", auth: false }
			},
			{
				path: "drawlxsd",
				name: "Drawlxsd",
				component: () => import("../views/Drawlxsd.vue"),
				meta: { title: "灵犀生图", auth: false }
			}
		]
	},
	{
		path: "/more",
		name: "More",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "更多应用" },
		children: [
			{
				path: "/",
				name: "MoreIndex",
				component: () => import("../views/More.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "mindmap",
				name: "MindMap",
				component: () => import("../views/MindMap.vue"),
				meta: { title: "思维导图" }
			},

		]
	},




	{
		path: "/photo",
		name: "Photo",
		layout: "dashboard",
		component: () => import("../views/DrawingCenter.vue"),
		meta: { title: "作品集", auth: true, layoutClass: "layout_profile" }
	},
	{
		path: "/createVideo",
		name: "Createvideo",
		component: () => import("../views/CreateVideo.vue"),
		meta: { title: "文生视频" }
	},

	{
		path: "/createppt",
		name: "createppt",
		component: () => import("../views/CreatePpt.vue"),
		meta: { title: "ppt大纲", keepAlive: false }
	},
	{
		path: "/test",
		name: "test",
		component: () => import("../views/test.vue"),
		meta: { title: "测试" }
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {}
	route.meta.layout = route.layout || parentLayout

	if (route.children) {
		route.children = route.children.map(childRoute => addLayoutToRoute(childRoute, route.meta.layout))
	}
	return route
}


routes = routes.map(route => addLayoutToRoute(route))

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth"
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true)
	} else {
		console.log('product.nymaite.cn')
		next()
	}
})

router.afterEach((to, from) => { })

export default router
